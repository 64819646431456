import React, { useState, useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import ButtonStack from '../Reusablecomponents/CustomButtonStack';
import CropSelector from "../Reusablecomponents/CropSelector";
import MultiDatePicker from '../Reusablecomponents/MultiDatePicker';
import dayjs from 'dayjs';
import { fetchImagesForCalender1, fetchImagesForCalender2 } from '../api';

import './ImageTrackerPopup.css';

function ImageTrackerPopup({ open, onClose, image, compareMode, selectedLocation }) {
    const [cropId, setCropId] = useState(null);
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().startOf('day'));
    const [images, setImages] = useState([]);
    const [locationId, setLocationId] = useState(null);

    useEffect(() => {
        if (selectedLocation) {
            setLocationId(selectedLocation.uuid);
        } else {
            setLocationId(null);
        }
        setImages([]);
        setStartDate(dayjs().startOf('day'));
        setEndDate(dayjs().startOf('day'));
    }, [open, selectedLocation]);

    const handleApplyClick = async () => {
        const startDateFormatted1 = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted2 = dayjs(endDate).format('YYYY-MM-DD');

        try {

            let calender1Images, calender2Images;

            if (locationId) {
                calender1Images = await fetchImagesForCalender1(startDateFormatted1, cropId, locationId);
                calender2Images = await fetchImagesForCalender2(endDateFormatted2, cropId, locationId);
            } else {
                calender1Images = await fetchImagesForCalender1(startDateFormatted1, cropId);
                calender2Images = await fetchImagesForCalender2(endDateFormatted2, cropId);
            }

            const imageOne = calender1Images.length > 0 ? {
                url: calender1Images[0].image_url,
                timestamp: calender1Images[0].timestamp,
                uuid: calender1Images[0].uuid
            } : null;

            const imageTwo = calender2Images.length > 0 ? {
                url: calender2Images[0].image_url,
                timestamp: calender2Images[0].timestamp,
                uuid: calender2Images[0].uuid
            } : null;

            const fetchedImages = [imageOne, imageTwo].filter(Boolean);

            onClose();
            navigate('/image-comparison', {
                state: {
                    startDate: startDateFormatted1,
                    endDate: endDateFormatted2,
                    images: fetchedImages,
                    cropId: cropId,
                    locationId: locationId,
                }
            });
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };



    return open ? (
        <div className="popup-div">
            <div className="popup-header">
                <Typography variant="h6">
                    ImageTracker
                </Typography>
            </div>
            <Stack spacing={2} p={2} className="popover-body">
                <div className="crop-selector-container" style={{ display: 'flex', flexDirection: 'column' }}>
                    <CropSelector setCropId={(id) => { setCropId(id) }}
                        onLocationChange={(location) => {
                            setLocationId(location ? location.uuid : 'All');
                        }}
                        showLocation={true} showCW={false} direction="vertical" />
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MultiDatePicker
                        startDate={startDate}
                        endDate={endDate}
                        onStartDateChange={setStartDate}
                        onEndDateChange={setEndDate}
                    />
                </LocalizationProvider>
                <ButtonStack
                    style={{ marginTop: '35px' }}
                    onButtonClick={handleApplyClick}
                    cancelLabel="Cancel"
                    sendLabel="Apply"
                    height="42px"
                    width="140px"
                    fontWeight="bold"
                    onCancelClick={onClose}
                />
            </Stack>
        </div>
    ) : null;
}

export default ImageTrackerPopup;