import React, { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CropSelector from "../Reusablecomponents/CropSelector";
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import MultiDatePicker from '../Reusablecomponents/MultiDatePicker';
import dayjs from 'dayjs';
import './ImageComparision.css';
import { fetchImagesForCalender1, fetchImagesForCalender2 } from '../api';


function ImageComparison() {
    const navigate = useNavigate();
    const location = useLocation();

    const { startDate, endDate, images, } = location.state || {
        startDate: null,
        endDate: null,
        images: [],

    };

    const [cropId, setCropId] = useState(null);
    const [locationId, setLocationId] = useState(null);
    const [currentImages, setCurrentImages] = useState(images);
    const [selectedStartDate, setSelectedStartDate] = useState(startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(endDate);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                let calender1Images, calender2Images;
                if (locationId) {
                    calender1Images = await fetchImagesForCalender1(selectedStartDate, cropId, locationId);
                    calender2Images = await fetchImagesForCalender2(selectedEndDate, cropId, locationId);
                } else {
                    calender1Images = await fetchImagesForCalender1(selectedStartDate, cropId);
                    calender2Images = await fetchImagesForCalender2(selectedEndDate, cropId);
                }

                const fetchedImages = [
                    calender1Images.length > 0 ? {
                        url: calender1Images[0].image_url,
                        timestamp: calender1Images[0].timestamp,
                        uuid: calender1Images[0].uuid
                    } : null,
                    calender2Images.length > 0 ? {
                        url: calender2Images[0].image_url,
                        timestamp: calender2Images[0].timestamp,
                        uuid: calender2Images[0].uuid
                    } : null
                ];

                setCurrentImages(fetchedImages);
            } catch (error) {
                console.error('Error fetching images:', error);
                setCurrentImages([null, null]);
            }
        };

        if (selectedStartDate && selectedEndDate && cropId) {
            fetchImages();
        }
    }, [selectedStartDate, selectedEndDate, cropId, locationId]);


    const handleStartDateChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        setSelectedStartDate(formattedDate);
    };

    const handleEndDateChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        setSelectedEndDate(formattedDate);
    };

    const handleBackToGallery = () => {
        navigate('/Image-Tracker');
    };

    const imageOne = currentImages[0];
    const imageTwo = currentImages[1];

    return (
        <Box display="flex" flexDirection="column" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="1300" bgcolor="#717171">
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" p={2} bgcolor="#F9F9F9">
                <CropSelector
                    setCropId={setCropId}
                    onLocationChange={setLocationId}
                    showLocation={true}
                    showCW={false}
                />
                <IconButton onClick={handleBackToGallery} sx={{
                    padding: '5px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
            <Box className="flex-container">
                <Box className="styled-box">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MultiDatePicker
                            startDate={dayjs(selectedStartDate)}
                            endDate={dayjs(selectedEndDate)}
                            onStartDateChange={handleStartDateChange}
                            onEndDateChange={handleEndDateChange}
                            showLabel={false}
                        />
                    </LocalizationProvider>
                </Box>
                <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">

                    {imageOne && imageTwo ? (
                        <ReactCompareSlider
                            itemOne={
                                <ReactCompareSliderImage
                                    src={imageOne.url}
                                    alt={`Image from ${dayjs(imageOne.timestamp).format('YYYY-MM-DD')}`}
                                    
                                />
                            }
                            itemTwo={
                                <ReactCompareSliderImage
                                    src={imageTwo.url}
                                    alt={`Image from ${dayjs(imageTwo.timestamp).format('YYYY-MM-DD')}`}
                                    
                                />
                            } 
                            style={{ height: 'calc(100vh - 75px)', maxWidth: 'calc(100vw - 320px)', objectFit: 'contain' }}
                        />
                    ) : (
                        <Box textAlign="center">
                            {!cropId && (
                                <Typography variant="h6" color="white">
                                    No image available for the selected crop.
                                </Typography>
                            )}
                            {!imageOne && (
                                <Typography variant="h6" color="white">
                                    No image available for the first selected date.
                                </Typography>
                            )}
                            {!imageTwo && (
                                <Typography variant="h6" color="white">
                                    No image available for the second selected date.
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default ImageComparison;