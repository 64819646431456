import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.css';
import CustomButton from '../Reusablecomponents/Custombutton';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import selectedNoteIcon from '../icons/selected_note.png';
import { fetchLatestData, fetchCalendarWeekdata } from '../api';
import { format } from 'date-fns';

dayjs.extend(weekOfYear);

const WEEKS_PER_PAGE = 3;
const TOTAL_WEEKS = 20;

let fetchId = 0;

const Gallery = ({ onThumbnailClick, selectedWeek, selectedLocation, cultivationZoneID, withNote }) => {
  const [thumbnailsByCW, setThumbnailsByCW] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isSpecificWeek, setIsSpecificWeek] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [endOfWeeks, setEndOfWeeks] = useState(false);
  const [noMoreImages, setNoMoreImages] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [noDataForSelectedWeek, setNoDataForSelectedWeek] = useState(null);


  const fetchThumbnailsForSelectedWeek = useCallback(async (week, selectedLocation, withNote) => {
    const startDate = dayjs().week(week).startOf('week').format('YYYY-MM-DD');
    const endDate = dayjs().week(week).endOf('week').format('YYYY-MM-DD');
    let result;
    if (selectedLocation && selectedLocation.name !== "All") {
      result = await fetchCalendarWeekdata(cultivationZoneID, startDate, endDate, selectedLocation, withNote);
    } else {
      result = await fetchCalendarWeekdata(cultivationZoneID, startDate, endDate, null, withNote);
    }

    return {
      [week]: result.filter(item =>
        !selectedLocation ||
        selectedLocation.name === "All" ||
        item.location.uuid === selectedLocation
      )
    };
  }, [cultivationZoneID, withNote]);

  const fetchThumbnailsAllWeek = useCallback(async (initialWeek, endWeek, selectedLocation, withNote) => {
    let result;
    if (selectedLocation && selectedLocation.name !== "All") {
      result = await fetchLatestData(cultivationZoneID, selectedLocation, withNote);
    } else {
      result = await fetchLatestData(cultivationZoneID, null, withNote);
    }

    return result.reduce((acc, item) => {
      const imageDate = dayjs(item.timestamp);
      const cw = imageDate.week();
      acc[cw] = acc[cw] || [];
      acc[cw].push(item);
      return acc;
    }, {});
  }, [cultivationZoneID]);

  const fetchThumbnailsByCW = useCallback(async () => {
    const currentFetchId = ++fetchId;

    setNoDataAvailable(false);

    try {
      let weeksData = [];
      const currentWeek = dayjs().week();
      let initialWeek = currentWeek;
      let hasData = false;

      // Fetch for a specific week 
      if (selectedWeek !== null && selectedWeek !== undefined) {
        const result = await fetchThumbnailsForSelectedWeek(selectedWeek, selectedLocation, withNote);
        if (fetchId !== currentFetchId) return;

        if (result[selectedWeek] && result[selectedWeek].length > 0) {
          const startOfWeek = dayjs().week(selectedWeek).startOf('week').format('YYYY-MM-DD');
          const endOfWeek = dayjs().week(selectedWeek).endOf('week').format('YYYY-MM-DD');
          weeksData.push({
            dateKey: `CW ${selectedWeek}, ${startOfWeek} - ${endOfWeek}`,
            thumbnails: result[selectedWeek]
          });
          hasData = true;
        } else {
          const startOfWeek = dayjs().week(selectedWeek).startOf('week').format('YYYY-MM-DD');
          const endOfWeek = dayjs().week(selectedWeek).endOf('week').format('YYYY-MM-DD');
          setNoDataForSelectedWeek({
            week: selectedWeek,
            startDate: startOfWeek,
            endDate: endOfWeek,
          });
        }
        setIsSpecificWeek(true);
      } else {
        // Fetch all weeks 
        while (!hasData && initialWeek > 0) {
          const result = await fetchThumbnailsAllWeek(initialWeek, currentWeek, selectedLocation, withNote);
          if (currentFetchId !== fetchId) return;

          if (result[initialWeek] && result[initialWeek].length > 0) {
            hasData = true;
          } else {
            initialWeek--;
          }
        }

        if (hasData) {
          let startWeek = initialWeek - (page - 1) * WEEKS_PER_PAGE;
          let endWeek = startWeek - (WEEKS_PER_PAGE - 1);

          for (let week = startWeek; week >= endWeek; week--) {
            const result = await fetchThumbnailsAllWeek(startWeek, endWeek, selectedLocation, withNote);
            if (currentFetchId !== fetchId) return;

            if (result[week] && result[week].length > 0) {
              const startOfWeek = dayjs().week(week).startOf('week').format('YYYY-MM-DD');
              const endOfWeek = dayjs().week(week).endOf('week').format('YYYY-MM-DD');
              weeksData.push({
                dateKey: `CW ${week}, ${startOfWeek} - ${endOfWeek}`,
                thumbnails: result[week]
              });
            }
          }
          setIsSpecificWeek(false);

        }
      }

      setThumbnailsByCW(prevThumbnails => {
        if (page === 1 || isSpecificWeek) {
          return weeksData;
        } else {
          const filteredWeeksData = weeksData.filter(
            newWeek => !prevThumbnails.some(prevWeek => prevWeek.dateKey === newWeek.dateKey)
          );
          return [...prevThumbnails, ...filteredWeeksData];
        }
      });

      setNoMoreImages(weeksData.length === 0);
      setNoDataAvailable(weeksData.length === 0 && !hasData && !isSpecificWeek);

    } catch (error) {
      console.error('Error fetching thumbnails:', error);
      setThumbnailsByCW([]);
      setNoDataAvailable(true);
    } finally {
      if (currentFetchId === fetchId) {
        setLoading(false);
      }
    }
  }, [selectedLocation, selectedWeek, page, fetchThumbnailsForSelectedWeek, fetchThumbnailsAllWeek, cultivationZoneID, withNote]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadMore(!loading && !isSpecificWeek && !noMoreImages && !endOfWeeks && !noDataAvailable);
    }, 300);
    return () => clearTimeout(timer);
  }, [loading, isSpecificWeek, noMoreImages, endOfWeeks, noDataAvailable]);

  const loadMore = useCallback(() => {
    if (!isSpecificWeek && !noMoreImages && !endOfWeeks && !noDataAvailable) {
      setLoading(true);
      setPage(prevPage => prevPage + 1);
    }
  }, [isSpecificWeek, noMoreImages, endOfWeeks, noDataAvailable]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
    setThumbnailsByCW([]);
    setNoMoreImages(false);
    setEndOfWeeks(false);
    setNoDataAvailable(false);
    setNoDataForSelectedWeek(null);
    fetchThumbnailsByCW();
  }, [selectedLocation, selectedWeek, cultivationZoneID, withNote]);

  useEffect(() => {
    setLoading(true);
    fetchThumbnailsByCW();
  }, [page, fetchThumbnailsByCW, withNote]);

  const memoizedThumbnails = useMemo(() => thumbnailsByCW, [thumbnailsByCW]);

  return (
    <div className="thumbnail-gallery">
      {memoizedThumbnails.map(({ dateKey, thumbnails }) => (
        <div key={dateKey} className="date-section">
          <h4>{dateKey}</h4>
          <div className="thumbnail-grid">
            {thumbnails.map((thumbnailData, index) => (
              <div key={thumbnailData.uuid} className="thumbnail-wrapper">
                <LazyLoadImage
                  src={thumbnailData.thumbnail_url}
                  alt={`Thumbnail ${index + 1}`}
                  className="thumbnail-item"
                  effect="blur"
                  onClick={() => onThumbnailClick(
                    thumbnailData.thumbnail_url,
                    thumbnails,
                    index,
                    thumbnailData.uuid,
                    thumbnailData.metadata,
                    thumbnailData.timestamp
                  )}
                />
                <div className="thumbnail-label">
                  {thumbnailData.message && (
                    <img src={selectedNoteIcon} alt="Unselected Note" className="unselected-note-icon" />
                  )}
                  {thumbnailData.location?.name ? (
                    <span>
                      {thumbnailData.location.name}
                      <br />
                      {thumbnailData.timestamp && format(thumbnailData.timestamp, 'd MMM yyyy, HH:mm')}
                    </span>
                  ) : (
                    'Location Not Available'
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="thumbnail-gallery">
        {noDataForSelectedWeek && (
          <div className="calendar-week">
            <h4>{`CW ${noDataForSelectedWeek.week}, ${noDataForSelectedWeek.startDate} - ${noDataForSelectedWeek.endDate}`}</h4>
          </div>
        )}
        {noDataForSelectedWeek && (
          <div className="no-data-note">
            <p>No data for selected calendar week.</p>
          </div>
        )}
      </div>
      {loading && (
        <div className="loading-container">
          <div className="loading-indicator">
            <CircularProgress size={40} style={{ color: '#05668D' }} />
          </div>
        </div>
      )}
      {!isSpecificWeek && !noMoreImages && !endOfWeeks && !loading && showLoadMore && !noDataAvailable && (
        <div className="button-gallery" style={{ width: '150px', height: '40px' }}>
          <CustomButton
            label="Load More"
            onClick={loadMore}
            backgroundColor="#6B6B6B"
            hoverBackgroundColor="#05668D"
            activeBackgroundColor="#05668D"
          />
        </div>
      )}
      {noDataAvailable && !isSpecificWeek && (
        <div className="no-data-note">
          <p>No data available for the selected criteria.</p>
        </div>
      )}
      {noMoreImages && !noDataAvailable && !isSpecificWeek && (
        <div className="end-message">
          <p>You have reached the end of displayed weeks. To explore more images, please use the CW dropdown menu.</p>
        </div>
      )}
    </div>
  );
};

export default Gallery;